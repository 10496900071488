import analytics from '@/shared-utils/analytics'

const filterNameConverter: Record<string, string> = {
  amenities: 'amenity',
  neighborhoods: 'neighborhood',
  hotelStarLevel: 'hotel_star_rating',
  guestRating: 'guest_rating',
  propertyTypes: 'property_types'
}

export function fireFilterBarChipGA4Event(
  filterName: string,
  filterItem: string,
  enabled: boolean
) {
  analytics.fireGA4Event({
    event: 'filter',
    attributes: {
      filter_type: 'search_form',
      filter_name: filterNameConverter[filterName],
      filter_item: filterItem.replaceAll(' ', '_'),
      page_name: 'homepage',
      product_name: 'hotel',
      enabled_flag: enabled ? 'y' : 'n'
    }
  })
}
export function fireFilterBarGA4Event() {
  analytics.fireGA4Event({
    event: 'display',
    attributes: {
      type: 'search_form',
      item_name: 'filter',
      page_name: 'homepage',
      product_name: 'hotel'
    }
  })
}
