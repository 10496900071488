import React from 'react'
import { format } from 'date-fns/format'
import { parse } from 'date-fns/parse'
import { addDays } from 'date-fns/addDays'
import { differenceInDays } from 'date-fns/differenceInDays'
import { parseISO } from 'date-fns/parseISO'

export const formatDate = (
  dateStr: string | null,
  dateFormat = 'MM/dd/yyyy'
) => {
  let formatedDate = ''
  try {
    if (dateStr) {
      formatedDate = format(parse(dateStr, 'yyyyMMdd', new Date()), dateFormat)
    }
  } catch (err) {
    formatedDate = ''
  }

  return formatedDate
}

export const addFormattedDays = (
  dateStr: string,
  days: number,
  dateFormat = 'yyyyMMdd'
) => {
  if (dateStr) {
    return format(
      addDays(parse(dateStr, 'yyyyMMdd', new Date()), days),
      dateFormat
    )
  }
  return ''
}

const now = Date.now()
export const defaultToday = format(now, 'yyyyMMdd')
export const defaultTomorrow = format(addDays(now, 1), 'yyyyMMdd')
export const defaultMaxDate = format(addDays(now, 330), 'yyyyMMdd')

// Shared methods for Flights, SOPQ Flights and RC
/**
 * @param {object} param0
 * @param {string | null} [param0.departureDate]
 * @param {string | null} [param0.arrivalDate] - The name of the employee.
 * @param {boolean} isOneWay
 */
export const getDateRange = (
  {
    departureDate,
    arrivalDate
  }: { departureDate: string | null; arrivalDate: string | null },
  isOneWay: boolean
) => {
  if (!departureDate) return ''
  const start = formatDate(departureDate)
  if (isOneWay) return start
  const end = formatDate(arrivalDate)
  return `${start} – ${end}`
}

export const getPackagesDateRange = ({
  startDate,
  endDate
}: {
  startDate: string | null
  endDate: string | null
}) => {
  if (!startDate) return ''
  return `${formatDate(startDate)} - ${formatDate(endDate)}`
}

export const getHotelDateRange = ({
  startDate,
  endDate
}: {
  startDate: string | null
  endDate: string | null
}) => {
  if (!startDate && !endDate) return ''
  const start = formatDate(startDate)
  const end = formatDate(endDate)
  return [start, end].join(' – ')
}

export const getRcMinDate = (pickupDate?: string) => {
  if (pickupDate) {
    return formatDate(pickupDate, 'yyyyMMdd')
  }
  return format(new Date(), 'yyyyMMdd')
}

export const getTimeOptions = () => {
  const times = new Array(48).fill(null).map((_, i) => i)

  return times.map(time => {
    const date = new Date(
      0,
      0,
      0,
      Math.floor(time / 2),
      time % 2 === 0 ? 0 : 30
    )
    const value = format(date, 'HH:mm')
    let label = format(date, 'h:mm a')
    if (value === '00:00') {
      label = 'Midnight'
    } else if (value === '12:00') {
      label = 'Noon'
    }
    return (
      <option key={value} value={value}>
        {label}
      </option>
    )
  })
}

export function formatPackagesDateFromSearchLink(url: string) {
  function parseDateFromPkgURL(dateMatch: RegExpMatchArray | null) {
    if (!dateMatch) return null
    return `${dateMatch[1]}-${dateMatch[2]}-${dateMatch[3]}`
  }

  const departureDateMatch = url.match(/departure-date=(\d{4})(\d{2})(\d{2})/)
  const returnDateMatch = url.match(/return-date=(\d{4})(\d{2})(\d{2})/)
  const departureDateISO = parseDateFromPkgURL(departureDateMatch)
  const returnDateISO = parseDateFromPkgURL(returnDateMatch)
  if (
    !departureDateMatch ||
    !returnDateMatch ||
    !departureDateISO ||
    !returnDateISO
  ) {
    return {
      formattedDepartureDate: '',
      formattedReturnDate: '',
      daysBetween: 0
    }
  }
  const formattedDepartureDate = format(parseISO(departureDateISO), 'MMM do')
  const formattedReturnDate = format(parseISO(returnDateISO), 'MMM do')

  const daysBetween = differenceInDays(
    parseISO(returnDateISO),
    parseISO(departureDateISO)
  )

  return { formattedDepartureDate, formattedReturnDate, daysBetween }
}
