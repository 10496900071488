import type { LOCATION_SEARCH_TYPE, TypeAheadConfigEndpoint } from '@/types'

export default function useTypeAheadForDisplayName(
  searchProduct: TypeAheadConfigEndpoint,
  skipCustomDisplayName = false
) {
  const isFlightRoute = searchProduct === 'flights'
  const itemDisplayNameTransform = (
    item: LOCATION_SEARCH_TYPE | Record<string, never> | null
  ) => {
    if (item === null) {
      return
    }

    if (isFlightRoute && item.type === 'AIRPORT') {
      const displayNameSegments = [
        item.cityName,
        ...((item.countryCode === 'US' || item.countryCode === 'CA') &&
        item.stateCode
          ? [item.stateCode]
          : []),
        ...(item.countryCode !== 'US' ? [item.country] : [])
      ]

      return `${displayNameSegments.join(', ')} (${item.id})`
    }
    return item.itemName
  }

  return isFlightRoute && !skipCustomDisplayName
    ? itemDisplayNameTransform
    : null
}
