import {
  assign,
  createMachine,
  type State,
  type StateSchema,
  type BaseActionObject,
  type ResolveTypegenMeta,
  type TypegenDisabled
} from 'xstate'
import fetch from 'isomorphic-unfetch'
import CONFIG from 'isomorphic-config'
import analytics from '@/shared-utils/analytics'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import type { City } from '../server/utils/typeahead-fetch-types'

export type CurrentLocationMachineState = State<
  CurrentLocationContext,
  CurrentLocationEvent,
  StateSchema<CurrentLocationContext>,
  CurrentLocationTypeState,
  ResolveTypegenMeta<
    TypegenDisabled,
    CurrentLocationEvent,
    BaseActionObject,
    BaseActionObject
  >
>

type CurrentLocationContext = {
  position: GeolocationPosition | null
  error: GeolocationPositionError | Error | null
  cityData: Partial<LOCATION_SEARCH_TYPE> | null
}

type CurrentLocationEvent =
  | { type: 'REQUEST' }
  | { type: 'success'; position: GeolocationPosition }
  | { type: 'error'; error: GeolocationPositionError | Error }

type CurrentLocationTypeState =
  | {
      value: 'idle'
      context: CurrentLocationContext
    }
  | {
      value: 'pending'
      context: CurrentLocationContext & {
        position: null
      }
    }
  | {
      value: 'gettingCurrentCity'
      context: CurrentLocationContext & {
        position: GeolocationPosition
      }
    }
  | {
      value: 'resolved'
      context: CurrentLocationContext & { cityData: LOCATION_SEARCH_TYPE }
    }
  | {
      value: 'rejected'
      context: CurrentLocationContext
    }

type GeoServiceErrorResponse = {
  resultCode: number
  resultMessage: string
  rguid: string
  src: string
}

type GeoServiceSuccessResponse = {
  resultMessage: string
  exactMatch: {
    matchedCity: City
    displayText: string
  } | null
  searchedLocation: LOCATION_SEARCH_TYPE | null
}

type GeoServiceCityData = Pick<
  LOCATION_SEARCH_TYPE,
  'itemName' | 'content' | 'lat' | 'lon' | 'cityID' | 'type'
>

function isGeoServiceResponseSuccessful(
  responseData: GeoServiceErrorResponse | GeoServiceSuccessResponse
): responseData is GeoServiceSuccessResponse {
  return (responseData as GeoServiceSuccessResponse).exactMatch !== undefined
}

export async function invokeFetchCurrentCityData(
  latitude: number,
  longitude: number
): Promise<Required<GeoServiceCityData>> {
  const url = `${CONFIG.client.search.retail.hotels}geo/${latitude}/${longitude}/5?version=1`
  const res = await fetch(url)
  const data = (await res.json()) as
    | GeoServiceSuccessResponse
    | GeoServiceErrorResponse
  if (isGeoServiceResponseSuccessful(data)) {
    if (data.exactMatch !== null && data.searchedLocation !== null) {
      const { lat, lon } = data.searchedLocation
      const {
        matchedCity: { cityID },
        displayText
      } = data.exactMatch
      if (lat !== undefined && lon !== undefined && displayText) {
        return {
          itemName: displayText,
          content: displayText,
          type: 'GEO',
          lat,
          lon,
          cityID: cityID.toString()
        }
      }
    }
    throw new Error('No city was matched for this latitude/longitude.')
  }
  const errorMessage = `Result code: ${data.resultCode}, rguid: ${data.rguid}, message: ${data.resultMessage}`
  analytics.logError({
    message: errorMessage
  })
  throw new Error(errorMessage)
}

function geoService() {
  return (cb: (event: CurrentLocationEvent) => void) => {
    if (!navigator.geolocation) {
      cb({
        type: 'error',
        error: new Error('Geolocation is not supported')
      })
      return
    }

    const geoWatch = navigator.geolocation.watchPosition(
      // sends back to parent
      position => cb({ type: 'success', position }),
      error => cb({ type: 'error', error })
    )

    // disposal function
    return () => navigator.geolocation.clearWatch(geoWatch)
  }
}

const shouldRequest = (context: CurrentLocationContext) =>
  context.cityData === null

const currentLocationMachine = createMachine<
  CurrentLocationContext,
  CurrentLocationEvent,
  CurrentLocationTypeState
>({
  id: 'geo',
  context: {
    error: null,
    cityData: null,
    position: null
  },
  initial: 'idle',

  states: {
    idle: {
      on: {
        REQUEST: [
          {
            target: 'pending',
            cond: shouldRequest
          },
          {
            target: 'resolved'
          }
        ]
      }
    },
    pending: {
      invoke: {
        src: geoService
      },
      on: {
        success: {
          target: 'gettingCurrentCity',
          actions: assign({
            position: (_, event) => event.position
          })
        },
        error: {
          target: 'rejected',
          actions: assign({
            error: (_, event) => event.error
          })
        }
      }
    },
    gettingCurrentCity: {
      invoke: {
        src: context =>
          context.position !== null
            ? invokeFetchCurrentCityData(
                context.position?.coords.latitude,
                context.position?.coords.longitude
              )
            : Promise.reject(),
        onDone: {
          target: 'resolved',
          actions: assign({
            cityData: (_, event) => event.data
          })
        },
        onError: {
          target: 'rejected',
          actions: assign({
            error: (_, event) => event.data
          })
        }
      }
    },
    resolved: {},
    rejected: {}
  },
  predictableActionArguments: true
})
export default currentLocationMachine
