/* eslint import/prefer-default-export: 0 */
import { append, compose, path, split } from 'ramda'
import { type FieldErrors } from 'react-hook-form'
import type { FlightFormStateType } from '@/components/Flights/types'

export const getErrorFromKey = compose(path, append('message'), split('.'))

export const getDateFieldBorderColors = (
  errors: FieldErrors<FlightFormStateType>,
  i: number
) => {
  return errors.flights?.[i]?.startDate ? 'red' : undefined
}

export const getDateFieldErrorMessage = (
  errors: FieldErrors<FlightFormStateType>,
  i: number,
  isRoundTripFlight = false
) => {
  // eslint-disable-next-line no-nested-ternary
  return errors.flights?.[i]?.startDate?.message ||
    errors.flights?.[i]?.endDate?.message
    ? isRoundTripFlight
      ? 'Please select a departing and returning date'
      : 'Please select a departing date'
    : undefined
}
