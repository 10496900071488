export const top20Filters = {
  '3000015284': [
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    {
      filterType: 'neighborhoods',
      code: '910050899',
      name: 'The Strip - Northeast'
    },
    {
      filterType: 'neighborhoods',
      code: '910054959',
      name: 'The Strip - Southwest'
    },
    {
      filterType: 'neighborhoods',
      code: '910050900',
      name: 'The Strip - Southeast'
    },
    {
      filterType: 'neighborhoods',
      code: '910054959',
      name: 'The Strip - Northwest'
    }
  ],
  '3000016152': [
    {
      filterType: 'neighborhoods',
      code: '910051081',
      name: 'Times Square'
    },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    {
      filterType: 'neighborhoods',
      code: '910051143',
      name: 'Empire State Building Area'
    }
  ],
  '3000003349': [
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' },
    { filterType: 'amenities', code: 'SPOOL', name: 'Swimming Pool' }
  ],
  '3000061781': [
    { filterType: 'amenities', code: 'INCLUSIVE', name: 'All Inclusive' },
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    {
      filterType: 'neighborhoods',
      code: '910051555',
      name: 'Cancun Hotel Zone North'
    },
    { filterType: 'amenities', code: 'ADULTONLY', name: 'Adults Only' }
  ],
  '3000003311': [
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    {
      filterType: 'neighborhoods',
      code: '910051516',
      name: 'Miami International Airport (MIA)'
    },
    { filterType: 'propertyTypes', code: 'HTLS', name: 'Hotels' },
    {
      filterType: 'neighborhoods',
      code: '910054952',
      name: 'Downtown - Port of Miami'
    }
  ],
  '3000003192': [
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'amenities', code: 'AIRSHUTTL', name: 'Airport Shuttle' },
    {
      filterType: 'neighborhoods',
      code: '910000540',
      name: 'Ft Lauderdale Beach Area'
    },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' }
  ],
  '3000004031': [
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' }
  ],
  '5000494493': [
    { filterType: 'amenities', code: 'INCLUSIVE', name: 'All Inclusive' },
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    {
      filterType: 'neighborhoods',
      code: '910052323',
      name: 'Punta Cana - Bavaro'
    },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    { filterType: 'amenities', code: 'ADULTONLY', name: 'Adults Only' }
  ],
  '3000003496': [
    { filterType: 'neighborhoods', code: '910004274', name: 'Midtown Atlanta' },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' }
  ],
  '3000001947': [
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    {
      filterType: 'neighborhoods',
      code: '910053111',
      name: 'Downtown Los Angeles'
    },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' }
  ],
  '3000033452': [
    {
      filterType: 'neighborhoods',
      code: '910052938',
      name: 'Downtown Toronto - Entertainment District'
    },
    {
      filterType: 'neighborhoods',
      code: '910052937',
      name: 'Downtown Toronto - Union Station'
    },
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    { filterType: 'propertyTypes', code: 'HTLS', name: 'Hotels' },
    {
      filterType: 'neighborhoods',
      code: '910052936',
      name: 'Downtown Toronto - Yorkville'
    }
  ],
  '3000024950': [
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    { filterType: 'neighborhoods', code: '910054627', name: 'Condado' },
    { filterType: 'amenities', code: 'BCHFRNT', name: 'Waterfront' },
    { filterType: 'amenities', code: 'SPOOL', name: 'Swimming Pool' },
    {
      filterType: 'neighborhoods',
      code: '910054628',
      name: 'Isla Verde - San Juan Airport'
    }
  ],
  '3000005381': [
    {
      filterType: 'neighborhoods',
      code: '910050245',
      name: 'Magnificent Mile - Gold Coast'
    },
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    {
      filterType: 'neighborhoods',
      code: '910054833',
      name: 'Downtown - River North'
    },
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' }
  ],
  '3000021312': [
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    {
      filterType: 'neighborhoods',
      code: '910050125',
      name: 'Houston Galleria'
    },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' },
    { filterType: 'neighborhoods', code: '910000160', name: 'Downtown Houston' }
  ],
  '3000040035': [
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    {
      filterType: 'neighborhoods',
      code: '910030344',
      name: 'Shinjuku-ku - Ikebukuro'
    },
    { filterType: 'neighborhoods', code: '910054396', name: 'Shibuya - Meguro' }
  ],
  '3000060600': [
    { filterType: 'amenities', code: 'INCLUSIVE', name: 'All Inclusive' },
    { filterType: 'hotelStarLevel', code: '4', name: '4+ Star Rating' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    {
      filterType: 'neighborhoods',
      code: '910052108',
      name: 'Historic Zone - South Shore'
    },
    { filterType: 'amenities', code: 'BCHFRNT', name: 'Waterfront' }
  ],
  '3000021082': [
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'neighborhoods', code: '910002320', name: 'Downtown Dallas' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' }
  ],
  '3000003431': [
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    {
      filterType: 'neighborhoods',
      code: '910000124',
      name: 'Downtown - Historic Ybor - Harbour Island'
    },
    { filterType: 'propertyTypes', code: 'HTLS', name: 'Hotel' },
    {
      filterType: 'neighborhoods',
      code: '910051511',
      name: 'Tampa Airport South - Westshore'
    }
  ],
  '3000001349': [
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' },
    { filterType: 'guestRating', code: '8', name: '8+ Guest Rating' },
    {
      filterType: 'neighborhoods',
      code: '910000328',
      name: 'Sky Harbor International Airport (PHX)'
    }
  ],
  '3000020633': [
    {
      filterType: 'neighborhoods',
      code: '910052700',
      name: 'Downtown Nashville - Metrocenter Area'
    },
    { filterType: 'amenities', code: 'FBRKFST', name: 'Free Breakfast' },
    { filterType: 'hotelStarLevel', code: '3', name: '3+ Star Rating' },
    {
      filterType: 'neighborhoods',
      code: '910052676',
      name: 'Vanderbilt - Music Row - West End'
    },
    { filterType: 'amenities', code: 'FPRKING', name: 'Free Parking' }
  ]
} as const

export function cityIdExistsInTop20Filters(
  cityId: string | undefined
): cityId is keyof typeof top20Filters {
  return cityId !== undefined && cityId in top20Filters
}
