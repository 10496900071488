import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Box } from 'pcln-design-system'
import useNewMobileTab from '@/hooks/useNewMobileTab'

// Element that creates the shadow effect style if enabled, or render an empty div if not
const ShadowEffectElement = styled(Box)`
  z-index: 10;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 24, 51, 0.5);
  pointer-events: none;
`

type ShadowEffectProps = {
  enabled?: boolean
  alternativeBlurHandling?: boolean
  children:
    | (({
        isEnabled,
        enableShadowState,
        disableShadowState
      }: {
        isEnabled?: boolean
        enableShadowState?: () => void
        disableShadowState: () => void
      }) => JSX.Element)
    | React.ReactNode
}

function ShadowEffect({
  alternativeBlurHandling,
  children,
  ...remainingProps
}: ShadowEffectProps) {
  const [isEnabled, setIsEnabled] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const disableShadowState = useCallback(() => {
    setIsEnabled(false)
  }, [])

  const enableShadowState = useCallback(() => {
    setIsEnabled(true)
  }, [])

  // Use Event as the type to satisfy both MouseEvent and FocusEvent overloads
  const handleDocumentClick = useCallback(
    (e: Event) => {
      if (
        nodeRef.current &&
        e.target instanceof Element &&
        !nodeRef.current.contains(e.target)
      ) {
        disableShadowState()
      }
    },
    [disableShadowState]
  )

  // Use React's FocusEvent.relatedTarget directly
  const handleAlternativeBlur = useCallback((e: React.FocusEvent) => {
    const target = e.relatedTarget as Element | null
    if (!target || !nodeRef.current?.contains(target)) {
      setIsEnabled(false)
    }
  }, [])

  const handleBlur = alternativeBlurHandling
    ? handleAlternativeBlur
    : disableShadowState
  const handleFocus = enableShadowState

  useEffect(() => {
    if (alternativeBlurHandling) {
      document.addEventListener('focusin', handleDocumentClick, true)
      document.addEventListener('click', handleDocumentClick, true)
      return () => {
        document.removeEventListener('focusin', handleDocumentClick, true)
        document.removeEventListener('click', handleDocumentClick, true)
      }
    }
  }, [alternativeBlurHandling, handleDocumentClick])

  const showNewMobileTabs = useNewMobileTab()

  return (
    <Box
      width={1}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...remainingProps}
    >
      {isEnabled && !showNewMobileTabs && <ShadowEffectElement />}
      <div
        ref={nodeRef}
        style={{
          width: '100%',
          position: 'relative',
          zIndex: isEnabled ? 20 : 'auto'
        }}
      >
        {typeof children === 'function'
          ? children({ isEnabled, enableShadowState, disableShadowState })
          : children}
      </div>
    </Box>
  )
}

export default ShadowEffect
