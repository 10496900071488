import { useEffect, useState } from 'react'
import { type AxiosResponse } from 'axios'
import pclnRequest from '@pcln/request'
import config from 'isomorphic-config'
import analytics from '@/shared-utils/analytics'
import hasGraphErrors from '@/shared-utils/graph-utils'
import type { GraphResponse, CityInfo, CityId } from '@/types'
import useBootstrapData from './useBootstrapData'

type CityInfoQueryGraphResponse = GraphResponse<{
  [key in `city_${number}`]: CityInfo
}>

let cachedRecommendedCityResults: CityInfo[] = []

type QueryAccumulator = {
  variableDefinitions: string[]
  cityInfoFields: string[]
  variables: Record<string, string>
}

const generateQuery = (locations: string[]) => {
  const uniqueLocations = Array.from(new Set(locations))
  const { variableDefinitions, cityInfoFields, variables } =
    uniqueLocations.reduce(
      (acc: QueryAccumulator, cityId, index) => {
        const variableName = `cityId${index}`
        acc.variableDefinitions.push(`$${variableName}: String!`)
        acc.cityInfoFields.push(`
        city_${cityId}: cityInfo(location: $${variableName}) {
          cityName
          countryCode
          countryName
          stateCode
          stateName
          latitude
          longitude
          itemName: displayCityName
          lat
          lon
          cityID: cityId
        }
      `)
        acc.variables[variableName] = cityId
        return acc
      },
      {
        variableDefinitions: [],
        cityInfoFields: [],
        variables: {}
      }
    )

  const query = `
    query fetchRecommendationSearches(${variableDefinitions.join(', ')}) {
      ${cityInfoFields.join(' ')}
    }
  `

  return {
    query,
    variables
  }
}

export default function useRecommendations(
  cityIdList: ReadonlyArray<CityId> = []
) {
  const { appName, appVersion } = useBootstrapData()
  const [recommendedCities, setRecommendedCities] = useState<CityInfo[]>([])
  useEffect(() => {
    let cancelRequest = false
    async function fetchRecommendations(cityIds: string[]) {
      if (cachedRecommendedCityResults.length > 0) {
        setRecommendedCities(cachedRecommendedCityResults)
        return
      }
      const { query, variables } = generateQuery(cityIds)
      try {
        const OPERATION_NAME = 'fetchRecommendationSearches'
        const { url, timeout } = config.client['pcln-graph']
        const response: AxiosResponse<CityInfoQueryGraphResponse> =
          await pclnRequest({
            url: `${url}?gqlOp=${OPERATION_NAME}`,
            timeout,
            method: 'POST',
            headers: {
              'apollographql-client-name': appName,
              'apollographql-client-version': appVersion
            },
            data: {
              query,
              variables,
              operationName: OPERATION_NAME
            }
          })
        if (hasGraphErrors(response.data)) {
          const { errors } = response.data
          analytics.logError({
            message: `Failed to hotelRecommendations api. Error message: ${errors[0]?.message}`
          })
          return
        }
        const { data } = response.data
        if (data) {
          cachedRecommendedCityResults = Object.values(data)
          if (cancelRequest) return
          setRecommendedCities(cachedRecommendedCityResults)
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : error?.toString() ?? 'Unknown Error'
        analytics.logError({
          message: `Failed to hotelRecommendations api. Error message: ${errorMessage}`
        })
      }
    }
    if (recommendedCities?.length > 0) {
      return
    }
    if (cityIdList.length > 0) {
      void fetchRecommendations(cityIdList.map(x => x.label))
    }

    return function cleanup() {
      cancelRequest = true
    }
  }, [recommendedCities, cityIdList, appName, appVersion])

  return recommendedCities
}
