import { useEffect, useState } from 'react'
import type { LOCATION_SEARCH_TYPE, CityId } from '@/types'
import type { RecentHotelSearches } from '@/components/TripActivity/types'
import useRecentSearchCard from './useRecentSearchCard'
import useRecommendations from './useRecommendations'

export default function useRecentSearchesFetched(
  cguid: string,
  appName: string,
  appVersion: string,
  signedIn: boolean
) {
  const [recentLocations, setRecentLocations] = useState<
    LOCATION_SEARCH_TYPE[]
  >([])
  const [cityIdList, setCityIdList] = useState<CityId[]>([])
  const data = useRecentSearchCard(
    cguid,
    { appName, appVersion },
    ['STAY'],
    signedIn
  )

  useEffect(() => {
    data.forEach(item => {
      if (item.productType === 'STAY') {
        const { cityId } = item as RecentHotelSearches
        setCityIdList(prevCityId => [...prevCityId, { label: cityId }])
      }
    })
  }, [data])

  const cityInfoList = useRecommendations(cityIdList)
  useEffect(() => {
    cityInfoList.forEach(item => {
      setRecentLocations(oldCityInfoList => [
        ...oldCityInfoList,
        {
          ...item,
          country: item.countryName,
          entered: '',
          highlightedName: null,
          id: item.cityID.toString(),
          type: 'CITY',
          cityID: item.cityID.toString()
        }
      ])
    })
  }, [cityInfoList])
  return recentLocations
}
