import React from 'react'
import styled from 'styled-components'
import { ButtonChip, Flex } from 'pcln-design-system'
import type {
  CityFilterItem,
  FilterValueHotel,
  SelectedTopFilters
} from '@/components/Hotels/types'
import { fireFilterBarChipGA4Event } from './ga4'

const Wrapper = styled(Flex)`
  gap: 8px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

export default function FilterBar({
  cityFilters,
  setSelectedTopFilters,
  selectedTopFilters,
  setIsPopoverOpen
}: {
  cityFilters: readonly CityFilterItem[]
  setSelectedTopFilters?: (
    value: React.SetStateAction<SelectedTopFilters>
  ) => void
  selectedTopFilters?: SelectedTopFilters
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (
    <Wrapper
      data-testid="filter-bar"
      flexWrap={['nowrap', null, 'wrap']}
      borderRadius="lg"
      mb={2}
      onClick={() => {
        setIsPopoverOpen(false)
      }}
    >
      {cityFilters.map(item => {
        const { code, filterType, name } = item
        const isFilterSelected =
          selectedTopFilters && filterType !== undefined
            ? (selectedTopFilters[filterType] as Set<FilterValueHotel>).has(
                `${filterType}_${code}_${name}`
              )
            : false
        return (
          <ButtonChip
            id="button-filter-chip"
            key={item.name}
            label={name}
            selected={isFilterSelected}
            onMouseDown={e => {
              e.preventDefault()
              document
                .getElementById(
                  'endLocation-typeahead-downshift-container-input'
                )
                ?.focus()
            }}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              document
                .getElementById(
                  'endLocation-typeahead-downshift-container-input'
                )
                ?.focus()
              if (setSelectedTopFilters) {
                setSelectedTopFilters((prev: SelectedTopFilters) => {
                  const newFilteredSet = new Set(prev[filterType])
                  const gaFilterItem =
                    filterType === 'neighborhoods' ? code : name
                  if (isFilterSelected) {
                    fireFilterBarChipGA4Event(filterType, gaFilterItem, false)
                    newFilteredSet.delete(`${filterType}_${code}_${name}`)
                  } else {
                    fireFilterBarChipGA4Event(filterType, gaFilterItem, true)
                    newFilteredSet.add(`${filterType}_${code}_${name}`)
                  }
                  return {
                    ...prev,
                    [filterType]: newFilteredSet
                  }
                })
              }
            }}
          />
        )
      })}
    </Wrapper>
  )
}
