import { useRouter } from 'next/router'
import useBootstrapData from './useBootstrapData'
import useSEMSearchHeadline from './useSEMSearchHeadline'
import useSeti from './useSeti'

export default function useNewMobileTab() {
  const { prePopData, isMobile } = useBootstrapData()
  const { pathname } = useRouter()
  const isHomepage = pathname === '/'
  const endLocation = prePopData?.hotels?.endLocation
  const shouldHideNewTab =
    Boolean(useSEMSearchHeadline()) || !!endLocation || !isHomepage
  const showNewMobileTabs =
    useSeti('MWEB_HP_MODAL_SEARCH', !shouldHideNewTab && isMobile) ===
      'VARIANT' &&
    !shouldHideNewTab &&
    isMobile

  return showNewMobileTabs
}
