import { useQuery } from '@tanstack/react-query'
import type { SearchProductList } from '@/components/TripActivity/types'
import fetchRecentTripSearches from '@/shared-utils/fetchRecentTripSearches'

type ApiMetadata = {
  appName: string
  appVersion: string
}

export default function useRecentSearchCard(
  cguid: string,
  apiMetadata: ApiMetadata,
  searchProducts: SearchProductList,
  signedIn: boolean
) {
  const { data = [] } = useQuery({
    queryKey: [searchProducts, cguid, apiMetadata, signedIn],
    queryFn: () =>
      fetchRecentTripSearches(cguid, apiMetadata, searchProducts, signedIn),
    staleTime: Infinity
  })

  return data
}
