import { useState, useEffect } from 'react'
import useBootstrapData from '@/hooks/useBootstrapData'

const useStickyBoxOffset = (isRecentSearchesVisible = false) => {
  const { isIOS } = useBootstrapData()
  const [bottomOffset, setBottomOffset] = useState(0)

  useEffect(() => {
    const updateBottomOffset = () => {
      if (window.visualViewport) {
        const viewportHeight = window.visualViewport.height
        const keyboardHeight = window.innerHeight - viewportHeight
        const isIOSKeyboardHeight = keyboardHeight > 0 && isIOS
        const isOtherKeyboardHeight = keyboardHeight ? keyboardHeight - 300 : 0
        let calculatedOffset = isIOSKeyboardHeight
          ? keyboardHeight - 210
          : isOtherKeyboardHeight

        if (isRecentSearchesVisible) {
          calculatedOffset -= 100
        }

        setBottomOffset(calculatedOffset)
      }
    }

    updateBottomOffset()

    window.visualViewport?.addEventListener('resize', updateBottomOffset)
    window.visualViewport?.addEventListener('scroll', updateBottomOffset)

    return () => {
      window.visualViewport?.removeEventListener('resize', updateBottomOffset)
      window.visualViewport?.removeEventListener('scroll', updateBottomOffset)
    }
  }, [isIOS, isRecentSearchesVisible])

  return bottomOffset
}

export default useStickyBoxOffset
