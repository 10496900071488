import React from 'react'
import { Text, Flex } from 'pcln-design-system'
import { Cars, Pin, Flights, CityView } from 'pcln-icons'
import { type GetItemPropsOptions } from 'downshift'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import DropdownItem from './DropdownItem'

type SearchItem = {
  isItemSelected: boolean
  index: number
  getItemProps: (
    options: GetItemPropsOptions<LOCATION_SEARCH_TYPE>
  ) => Record<string, unknown>
  item: LOCATION_SEARCH_TYPE & { skipFeedbackCall?: boolean }
  gaTracking?: () => void
  isTwoLineDisplay?: boolean
}

function SearchItem({
  isItemSelected,
  index,
  getItemProps,
  item,
  gaTracking = () => {
    // leave blank to prevent error if no tracking provided
  },
  isTwoLineDisplay
}: SearchItem) {
  const iconName = item.type
  const iconDesc = `${iconName}-${index}`
  const ICONS = {
    PARTNER_LOC: Cars,
    POI: Pin,
    AIRPORT: Flights,
    CITY: CityView
  } as const

  const Icon =
    iconName === 'PARTNER_LOC' ||
    iconName === 'POI' ||
    iconName === 'AIRPORT' ||
    iconName === 'CITY'
      ? ICONS[iconName]
      : Pin
  const displayText = item.itemName
  const { displayLine1, displayLine2 } = item

  return (
    <DropdownItem
      isHighlighted={isItemSelected}
      {...getItemProps({
        item,
        index,
        onClick: () => {
          gaTracking()
        }
      })}
    >
      <Icon
        color="primary"
        mx={2}
        aria-label={iconName}
        desc={iconName}
        descId={`${iconDesc}_ICON`}
        title={iconName}
        titleId={iconDesc}
        size={24}
      />

      {isTwoLineDisplay ? (
        <Flex flexDirection="column" py={2} mx={2}>
          <Text textStyle="subheading5" mb={1}>
            {displayLine1}
          </Text>
          <Text textStyle="subheading6" color="text.tint">
            {displayLine2}
          </Text>
        </Flex>
      ) : (
        <Text textStyle="subheading5" py={3} mx={2}>
          {displayText}
        </Text>
      )}
    </DropdownItem>
  )
}

export default SearchItem
