import config from 'isomorphic-config'
import analytics from '@/shared-utils/analytics'
import { CATCH_BLOCK_ERROR, GQL_QUERY_ERROR } from '@/constants/errorMessages'
import getCookieByName from '@/shared-utils/get-cookie-by-name'
import type { GraphResponseData } from '@/types'
import type {
  AirportDetails,
  RecentTripSearches,
  SearchProductList
} from '@/components/TripActivity/types'
import {
  fetchAndHandleErrors,
  parseCatchBlockErrors
} from './splunk-logging-helper'
import { jsonContentTypeHeader } from '../server/constants'
import isAbortSignalTimeoutSupported from './is-abortSignal-supported'
import getAuthorizationToken from './get-authorization-token'

type ApiMetadata = {
  appName: string
  appVersion: string
}

type ProductType =
  | 'PRODUCT_TYPE_UNSPECIFIED'
  | 'STAY'
  | 'DRIVE'
  | 'FLY'
  | 'UNRECOGNIZED'

type RecentSearchFlight = {
  productType: ProductType

  tripType: string
  travelStartDate: string
  travelEndDate: string
  numberOfAdults: number
  numberOfChildren: number
  cabinClass: string
  nonStopFlight: boolean
  originAirportInfo: AirportDetails
  destinationAirportInfo: AirportDetails
  destinationLatitude: string
  destinationLongitude: string
  creationTimestamp: string
  listingsUrl: string
}

type RecentSearchHotel = {
  productType: ProductType
  checkInDate: string
  checkOutDate: string
  cityId: string
  cityName: string
  numberOfRooms: number
  numberOfAdults: number
  numberOfChildren: number
  stateCode: string
  countryName: string
  latitude: string
  longitude: string
  creationTimestamp: string
  listingsUrl: string
}

type RecentSearchRentalCar = {
  productType: ProductType
  pickupDateTime: string
  returnDateTime: string
  pickUpDropOffSameFlag: boolean
  pickupLocationId: string
  pickupLocationType: string
  pickupLocationName: string
  returnLocationId: string
  returnLocationType: string
  returnLocationName: string
  pickupLocationStateCode: string
  pickupLocationCountryName: string
  pickupLatitude: string
  pickupLongitude: string
  creationTimestamp: string
  listingsUrl: string
}

type RecentSearchApiResponse =
  | RecentSearchFlight
  | RecentSearchHotel
  | RecentSearchRentalCar

type RecentSearchQueryResultSignedIn<T> = {
  readonly getRecentSearchesByAuthTokenAndCguid: T
}

type RecentSearchQueryResultSignedOut<T> = {
  readonly getRecentSearchesByCguid: T
}

type RecentSearchGraphResponse =
  | GraphResponseData<
      RecentSearchQueryResultSignedOut<ReadonlyArray<RecentSearchApiResponse>>
    >
  | GraphResponseData<
      RecentSearchQueryResultSignedIn<ReadonlyArray<RecentSearchApiResponse>>
    >

function isValidGraphQLResponse(
  payload: unknown
): payload is RecentSearchGraphResponse {
  if (payload && payload instanceof Object) {
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      ('getRecentSearchesByCguid' in payload.data ||
        'getRecentSearchesByAuthTokenAndCguid' in payload.data)
    ) {
      return true
    }
  }

  return false
}

const hotelQuery = `
    ... on RecentSearchHotel {
        productType
        checkInDate
        checkOutDate
        cityId
        cityName
        numberOfAdults
        numberOfChildren
        listingsUrl
    }

`

const flightQuery = `
    ... on RecentSearchFlight { 
        productType
        tripType
        travelStartDate
        travelEndDate
        numberOfAdults
        numberOfChildren
        cabinClass
        originAirportInfo {
            city
            code
            name
            state
            country
        }
        destinationAirportInfo {
            city
            code
            name
            state
            country
        }
        listingsUrl
    }
`

const carQuery = `
    ... on RecentSearchRentalCar {
        productType
        pickupDateTime
        returnDateTime
        pickupLocationId
        pickupLocationName
        returnLocationId
        returnLocationName
        listingsUrl
    }
`

async function fetchRecentTripSearches(
  cguid: string,
  apiMetadata: ApiMetadata,
  searchProducts: SearchProductList,
  signedIn: boolean
) {
  const { url, timeout } = config.client['pcln-graph']

  const authToken = getCookieByName('dmc') || ''

  const requestUrl = signedIn
    ? `${url}?gqlOp=getRecentSearchesByAuthTokensAndCguid`
    : `${url}?gqlOp=getRecentSearchesByCguid`

  const commonOptions = {
    method: 'POST',

    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(timeout)
      : undefined
  }

  const options = signedIn
    ? {
        ...commonOptions,
        headers: {
          authToken,
          ...jsonContentTypeHeader,
          'apollographql-client-name': apiMetadata.appName,
          'apollographql-client-version': apiMetadata.appVersion,
          Authorization: getAuthorizationToken()
        },

        body: JSON.stringify({
          query: `
        query CustomerSearches($authToken: String!, $cguid: String!) {     
          getRecentSearchesByAuthTokenAndCguid(authToken: $authToken, cguid: $cguid) {
                ${searchProducts.includes('STAY') ? hotelQuery : ''}
                ${searchProducts.includes('FLY') ? flightQuery : ''}
                ${searchProducts.includes('DRIVE') ? carQuery : ''}
            }
        }
      `,
          variables: {
            cguid,
            authToken
          }
        })
      }
    : {
        ...commonOptions,
        headers: {
          ...jsonContentTypeHeader,
          'apollographql-client-name': apiMetadata.appName,
          'apollographql-client-version': apiMetadata.appVersion
        },
        body: JSON.stringify({
          query: `
        query CustomerSearches($cguid: String!) {      
          getRecentSearchesByCguid(cguid: $cguid) {
                ${searchProducts.includes('STAY') ? hotelQuery : ''}
                ${searchProducts.includes('FLY') ? flightQuery : ''}
                ${searchProducts.includes('DRIVE') ? carQuery : ''}
            }
        }
      `,
          variables: {
            cguid
          }
        })
      }

  const serviceName = signedIn
    ? 'getRecentSearchesByAuthTokenAndCguid'
    : 'getRecentSearchesByCguid'

  const requestData = {
    requestUrl: 'pcln-graph',
    requestBody: JSON.stringify({
      cguid
    }),
    requestMethod: 'POST'
  }

  try {
    const payload = await fetchAndHandleErrors({
      requestUrl,
      options
    })

    if (payload.responseErrorData) {
      analytics.logError({
        serviceName,
        message: payload.message,
        ...payload.responseErrorData,
        ...requestData
      })

      return []
    }

    if (isValidGraphQLResponse(payload)) {
      return (
        'getRecentSearchesByCguid' in payload.data
          ? payload.data.getRecentSearchesByCguid
          : payload.data.getRecentSearchesByAuthTokenAndCguid
      ) as ReadonlyArray<RecentTripSearches>
    }

    if (payload.data.errors) {
      const { errors } = payload.data

      analytics.logError({
        error: JSON.stringify(errors),
        message: GQL_QUERY_ERROR,
        serviceName,
        ...requestData
      })
    }

    analytics.logError({
      message: `The Recent Search graph query response is not as per contract`,
      payload: JSON.stringify(payload)
    })

    return []
  } catch (error) {
    /* istanbul ignore next */
    analytics.logError({
      error: parseCatchBlockErrors(error),
      message: CATCH_BLOCK_ERROR,
      serviceName,
      ...requestData
    })
    /* istanbul ignore next */
    return []
  }
}

export default fetchRecentTripSearches
