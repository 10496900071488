import { getPlaceData, buildShopSearchForm } from '@pcln/build-shop-search-form'
import { format } from 'date-fns/format'
import { toDate } from 'date-fns/toDate'
import { min } from 'date-fns/min'
import { max } from 'date-fns/max'
import { parseISO } from 'date-fns/parseISO'
import type { LOCATION_SEARCH_TYPE, CityInfo } from '@/types'
import { type ChildrenAges } from '@pcln/traveler-selection'
import type {
  HotelDetail,
  MultiHotelFormState
} from '@/components/Hotels/SearchForm/MultiHotelSearchForm/types'
import { formatDate } from './date-helper'
import getComponentsFromTripType from './get-components-from-triptypes'

type PackageSearchParams = {
  roomCount: number
  adultCount: number
  childrenCount: number
  childrenAges: ChildrenAges
  startDate: string
  endDate: string
  tripType: string
  endLocationObject: LOCATION_SEARCH_TYPE | CityInfo | null
  startLocationObject?: LOCATION_SEARCH_TYPE | null
  hotelStartDate?: string
  hotelEndDate?: string
  isPartialStayDateValid?: boolean
  isRecommendedTrip?: boolean
  includeBasicEconomy?: boolean
  gaCategory?: string
}

function isLocationFromRecommendedSearches(
  item: LOCATION_SEARCH_TYPE | CityInfo
): item is CityInfo {
  return !('type' in item && 'id' in item)
}

function transformLocation(
  location: LOCATION_SEARCH_TYPE | CityInfo | null
): LOCATION_SEARCH_TYPE | null {
  if (location) {
    // pkg-search uses location.id to create the /relax url
    // for Recommended Searches, this property is undefined.
    if (isLocationFromRecommendedSearches(location)) {
      return {
        ...location,
        id: location.cityID.toString(),
        cityID: location.cityID.toString(),
        type: 'CITY'
      } as const
    }
    if (location.type === 'POI' && location.cityID && location.cityName) {
      return {
        ...location,
        cityID: location.cityID.toString(),
        type: 'CITY'
      } as const
    }
  }
  return location
}

function transformChildren(childrenCount: number, childrenAges: ChildrenAges) {
  if (childrenCount === 0) return {}
  return Object.values(childrenAges).reduce<Record<`child${number}`, string>>(
    (acc, current, index) => {
      const newKey = `child${index + 1}` as const
      acc[newKey] = current
      return acc
    },
    {}
  )
}

export default function packagesSearch({
  roomCount,
  adultCount,
  childrenCount,
  childrenAges,
  startDate,
  endDate,
  startLocationObject,
  endLocationObject,
  hotelStartDate = '',
  hotelEndDate = '',
  tripType,
  isPartialStayDateValid,
  isRecommendedTrip = false,
  includeBasicEconomy = true,
  ...rest
}: Readonly<PackageSearchParams>) {
  const childrenUpdated = transformChildren(childrenCount, childrenAges)
  const endLocationObjectCorrected = transformLocation(endLocationObject)
  const searchRequest = {
    rooms: roomCount,
    adultCount,
    childCount: childrenCount,
    children: childrenUpdated,
    departureDate: formatDate(startDate),
    departureLocation: startLocationObject || endLocationObjectCorrected,
    returnDate: formatDate(endDate),
    arrivalLocation: endLocationObjectCorrected,
    pivotComponentKey: 0,
    isRecommendedTrip,
    components: getComponentsFromTripType(tripType, {
      isPartialStayDateValid,
      includeBasicEconomy,
      hotelStartDate: formatDate(hotelStartDate, 'yyyy-MM-dd'),
      hotelEndDate: formatDate(hotelEndDate, 'yyyy-MM-dd'),
      roomCount,
      isCarRequired: tripType.includes('DRIVE'),
      ...rest
    })
  }
  const form = buildShopSearchForm('/shop/search/', searchRequest)
  form.submit()
}

function buildHotels(hotels: readonly HotelDetail[], roomCount: number) {
  return hotels.map((hotel, index) => ({
    type: 'STAY',
    productId: 5,
    index,
    query: {
      travelStartDate: {
        value: format(toDate(parseISO(hotel.startDate)), 'yyyy-MM-dd')
      },
      travelEndDate: {
        value: format(toDate(parseISO(hotel.endDate)), 'yyyy-MM-dd')
      },
      totalNumRooms: roomCount,
      destination: getPlaceData(hotel.endLocation)
    }
  }))
}

function getEarliestDate(hotels: readonly HotelDetail[]) {
  const dates = hotels.map(h => toDate(parseISO(h.startDate)))
  return format(min(dates), 'MM/dd/yyyy')
}

function getLatestDate(hotels: readonly HotelDetail[]) {
  const dates = hotels.map(h => toDate(parseISO(h.endDate)))
  return format(max(dates), 'MM/dd/yyyy')
}

export function multiHotelSearch(data: MultiHotelFormState) {
  const { hotels, travelers } = data
  const { children, childrenAges, rooms, adults } = travelers
  const childrenUpdated = transformChildren(children, childrenAges)
  const updatedHotels = hotels.map(hotel => {
    const { endLocation } = hotel
    if (!endLocation) return hotel
    return {
      ...hotel,
      endLocation: transformLocation(endLocation)
    }
  })

  const searchRequest = {
    rooms,
    adultCount: adults,
    childCount: children,
    children: childrenUpdated,
    pivotComponentKey: 0,
    components: buildHotels(updatedHotels, rooms),
    arrivalLocation: updatedHotels?.[0].endLocation ?? null,
    departureLocation: updatedHotels?.[0].endLocation ?? null,
    departureDate: getEarliestDate(updatedHotels),
    returnDate: getLatestDate(updatedHotels)
  }

  const url = '/shop/search/itinerary'
  const form = buildShopSearchForm(url, searchRequest)
  form.submit()
}
