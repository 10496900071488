import { useEffect, useState } from 'react'
import { eqProps, uniqWith } from 'ramda'
import {
  AIR_SEARCH_DATA,
  getPersistedSearchDataByKeys,
  type PERSISTED_SEARCH_KEY
} from '@/shared-utils/local-forage-helper'
import type { LOCATION_SEARCH_TYPE } from '@/types'

export default function useRecentSearchesLocalStorage(
  key: PERSISTED_SEARCH_KEY
) {
  const [recentStartLocations, setRecentStartLocations] = useState<
    LOCATION_SEARCH_TYPE[]
  >([])
  const [recentEndLocations, setRecentEndLocations] = useState<
    LOCATION_SEARCH_TYPE[]
  >([])
  const isFlight = key === AIR_SEARCH_DATA

  useEffect(() => {
    void getPersistedSearchDataByKeys([key], true).then(recentSearches => {
      if (recentSearches) {
        const startLocations = recentSearches
          .map(search =>
            isFlight ? search.flights?.[0]?.startLocation : search.startLocation
          )
          .filter(Boolean)
        const endLocations = recentSearches
          .map(search =>
            isFlight ? search.flights?.[0]?.endLocation : search.endLocation
          )
          .filter(Boolean)

        if (startLocations.length) {
          setRecentStartLocations(
            uniqWith(
              eqProps('itemName'),
              startLocations as LOCATION_SEARCH_TYPE[]
            )
          )
        }
        if (endLocations.length) {
          setRecentEndLocations(
            uniqWith(
              eqProps('itemName'),
              endLocations as LOCATION_SEARCH_TYPE[]
            )
          )
        }
      }
    })
  }, [key, isFlight])

  return [recentStartLocations, recentEndLocations]
}
